import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueWechatTitle from 'vue-wechat-title'
const messages = {
    jp: {
        lang: '日本語',
        find: 'UIDを見つける方法',
        btnText: '受け取る',
        step1: '1.ゲームに入って画面の左上にあるアバターをタップして[プロファイル]タブを開きましょう。',
        step2: '2.プロフィールページの左上に、UIDが表示されます。',
        errTip: 'アカウントが見つかりません',
        'tip-0': 'おめでとうございます！ギフトはビンゴワイルドのインボックスに送信されました。',
        'tip-2': 'あなたはギフトを受け取りました。',
        "tip-3": "このギフトはすでに受け取られています。",
        "tip-4": 'ギフトの有効期限が切れています。',
        router:'無料ギフト'
    },
    en: {
        lang: 'English',
        find: 'How to find UID',
        btnText: 'Claim',
        step1: "1. Open the game and find the avatar in the top left of the screen and open the 'Profile' tab.",
        step2: "2. You can find your UID in the top left of the Profile page.",
        errTip: 'This account does not exist',
        'tip-0': 'Congratulations! Gift has been sent to your inbox in Bingo Wild.',
        'tip-2': 'You have already claimed this gift.',
        "tip-3": "This gift has been claimed.",
        "tip-4": 'This gift has expired.',
        router:'Free Gift'
    }
}

const i18n = new createI18n({
    locale: "en",
    messages
})

createApp(App).use(i18n).use(store).use(router).use(ElementPlus).use(VueWechatTitle).mount('#app')
