<template>
  <div class="home" ref="home">
    <div class="logo">
      <img src="../assets/images/pc_logo.png" alt="" />
      <div class="content" v-show="show">
        <div class="lang">
          <span>{{ t("lang") }}</span>
          <el-popover
            placement="bottom"
            :width="100"
            trigger="click"
            ref="myPopover"
          >
            <template #reference>
              <i></i>
            </template>
            <ul class="change_item">
              <li
                v-for="(item, index) in langList"
                :key="index"
                @click="changeLang(item)"
              >
                {{ item.label }}
              </li>
            </ul>
          </el-popover>
        </div>
        <div class="inp-group" v-if="!showTips">
          <div class="tips">
            <span>UID:</span>
            <span v-if="uidError">{{ t("errTip") }}</span>
          </div>
          <el-input v-model="value" @input="formatValue(value)" />
          <div class="button" @click="sendEvent">
            <span>{{ t("btnText") }}</span>
          </div>
          <div class="tip_text" @click="howEvent">{{ t("find") }}</div>
        </div>
        <div class="tipbox" v-else>
          <span v-show="show0">{{ t("tip-0") }}</span>
          <span v-show="show_2">{{ t("tip-2") }}</span>
          <span v-show="show_3">{{ t("tip-3") }}</span>
          <span v-show="show_4">{{ t("tip-4") }}</span>
        </div>
      </div>
      <div class="how-content" v-show="!show">
        <div class="header">
          <span>{{ t("find") }}</span>
          <span class="close" @click="closeEvent"> </span>
        </div>
        <div class="pro-item">
          <span>{{ t("step1") }}</span>
          <img src="../assets/images/pc_1.png" alt="" />
        </div>
        <div class="pro-item">
          <span>{{ t("step2") }}</span>
          <img src="../assets/images/pc_2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="iphone_logo">
      <div class="logo_img">
        <img src="../assets/images/iphone_logo.png" alt="" />
      </div>
      <div class="iphone_content">
        <div class="tips_img">
          <img src="../assets/images/iphone_start.png" alt="" />
        </div>
        <div class="play_img" @click="playNow">
          <img src="../assets/images/iphone_button.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
const { t, locale } = useI18n();
const route = useRoute();
const myPopover = ref(null);
const value = ref("");
const show = ref(true);
const showTips = ref(false);
const show0 = ref(false);
const show_2 = ref(false);
const show_3 = ref(false);
const show_4 = ref(false);
const uidError = ref(false);
const link_str = ref("");
const langList = reactive([
  {
    label: "English",
    value: "en",
  },
  {
    label: "日本語",
    value: "jp",
  },
]);

const http = axios.create({
  baseURL: "https://server.gift.bingowild.vividjoangame.com/",
});

onMounted(() => {
  const url = location.href;
  let params = getUrlParams(url) || "";
  if (params.link_str) {
    link_str.value = params.link_str;
  }
  // window.addEventListener("orientationchange", orientationchangeHandler);
});
const getUrlParams = (url) => {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.indexOf("?") !== -1 ? url.split("?")[1] : "";
  // 创建空对象存储参数
  let obj = {};
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split("&");
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    let arr = paramsArr[i].split("=");
    obj[arr[0]] = arr[1];
  }
  return obj;
};

const howEvent = () => {
  show.value = !show.value;
};
const closeEvent = () => {
  show.value = !show.value;
};
const formatValue = () => {
  value.value = value.value.replace(/[^\d]/g, "");
};

const changeLang = (item) => {
  locale.value = item.value;
  myPopover.value.hide();
};
const sendEvent = () => {
  if (!value.value) {
    ElMessage.warning("UID不能为空");
    return;
  }
  const testlink = route.params.testlink;
  http
    .get(`/reward_link_send_inbox/?link_str=${testlink}&user_id=${value.value}`)
    .then((res) => {
      if (res.data == 0) {
        show0.value = true;
        showTips.value = true;
      } else if (res.data == -2) {
        show_2.value = true;
        showTips.value = true;
      } else if (res.data == -3) {
        show_3.value = true;
        showTips.value = true;
      } else if (res.data == -4) {
        show_4.value = true;
        showTips.value = true;
      } else if (res.data == -1) {
        if (locale.value == "en") {
          ElMessage.error("This gift has expired.");
        } else {
          ElMessage.error("ギフトの有効期限が切れています。");
        }
      } else if (res.data == -5) {
        uidError.value = true;
      }
    });
};

const playNow = () => {
  const testlink = route.params.testlink;
  window.location.href = `https://rayr.adj.st?adj_t=imyuh68_ej6eas2&adj_label=${testlink}`;
};
</script>
<style lang='scss' scoped>
.home {
  width: 100%;
  height: 100%;
  background: url("../assets/images/iphone_bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  .logo {
    display: none;
    .content {
      display: none;
    }
  }

  .iphone_logo {
    display: block;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    img {
      width: 100%;
    }

    .logo_img {
      margin: 0 auto;
      width: 80%;
    }
    .iphone_content {
      margin-bottom: 20%;
      .tips_img {
        width: 80%;
        margin: 0 auto;
        margin-top: 25%;
      }
      .play_img {
        width: 60%;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}
@media (min-width: 500px) {
  .home {
    background: url("../assets/images/iphone_bg.png") no-repeat;
    background-size: cover;
    background-position: center;
    .iphone_logo {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo_img {
        display: none;
      }
      .iphone_content {
        width: 50%;
        margin: 0;
        .tips_img {
          margin: 0 auto;
        }
      }
    }
    .logo {
      display: block;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 35%;
      }
    }
  }
}
@media (min-width: 915px) {
  .home {
    background: url("../assets/images/iphone_bg.png") no-repeat;
    background-size: 100%;
    background-position: center;
    .logo {
      display: none;
    }
    .iphone_logo {
      height: 100%;
      justify-content: space-evenly;
      .logo_img {
        width: 50%;
        height: 50%;
        display: block;
      }
      .iphone_content {
        width: 50%;
      }
    }
  }
}
@media (min-width: 1024px) {
  .home {
    background: url("../assets/images/pc_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
    .logo {
      display: block;
      display: flex;
    }
    .iphone_logo {
      height: 50%;
      display: flex;
      .logo_img {
        display: none;
      }
      .iphone_content {
        width: 50%;
      }
    }
  }
}

@media (min-width: 1200px) {
  .home {
    width: 100%;
    height: 100%;
    background: url("../assets/images/pc_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center;

    .iphone_logo {
      display: none;
    }

    .logo {
      width: 40%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 80%;
        margin-bottom: 10vh;
      }

      .content {
        width: 70%;
        background: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        display: block;
        .lang {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 30px;

          span {
            color: #2b90bd;
            font-size: 18px;
            margin: 0 5px;
          }

          i {
            width: 15px;
            height: 15px;
            background: url("../assets/images/pc_icon.png") no-repeat;
            background-size: cover;
            cursor: pointer;
          }
        }

        .inp-group {
          padding: 0 15px 50px 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .tips {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            span:nth-child(1) {
              color: #2b90bd;
              font-size: 30px;
            }

            span:nth-child(2) {
              color: #f66527;
              font-size: 18px;
            }
          }

          .el-input {
            width: 97%;
          }

          .button {
            margin-top: 30px;
            width: 100%;
            height: 70px;
            background: url("../assets/images/bg_button.png") no-repeat;
            background-size: 100% 100%;
            text-align: center;
            line-height: 70px;
            cursor: pointer;

            span {
              font-size: 40px;
              color: #fff;
              font-weight: bold;
            }
          }

          .tip_text {
            text-align: center;
            color: #2b90bd;
            font-size: 18px;
            cursor: pointer;
          }
        }

        .tipbox {
          padding: 100px 0;
          text-align: center;
          font-size: 32px;
          color: #f89a02;
          font-weight: bold;
          text-align: center;
        }
      }

      .how-content {
        width: 32%;
        height: 80vh;
        overflow-y: scroll;
        background: #fff;
        position: absolute;
        top: 10%;
        padding: 20px 30px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #2b90bd;
          margin-bottom: 15px;

          span:nth-child(1) {
            font-size: 24px;
          }

          .close {
            cursor: pointer;
            width: 18px;
            height: 18px;
            background: url("../assets/images/pc_close.png") no-repeat;
            background-size: cover;
          }
        }

        .pro-item {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          span {
            font-size: 16px;
            color: #808080;
          }

          img {
            width: 100%;
            height: auto;
            margin-top: 15px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .change_item {
    li {
      line-height: 28px;
      cursor: pointer;
    }

    li:hover {
      background: #eee;
    }
  }
}
</style>
<style>
.el-input__wrapper {
  border: 1px solid #2b90bd !important;
}

.el-input__wrapper .el-input__inner {
  text-align: center !important;
  font-size: 18px !important;
  color: #2b90bd !important;
}
</style>
